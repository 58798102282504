<style scoped>
.ck {
  cursor: pointer;
}
</style>
<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">金额统计</div>
      <div class="content-box">
        <template>
          <el-table :data="dataList" stripe style="width: 100%" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
            <el-table-column prop="payType" label="通道编码">
              <template slot-scope="s">
                <el-link style="color: green; margin-right: 5px; font-size: 14px">{{s.row.payType}}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="channelAlias" label="通道名称">
              <template slot-scope="s">
                <el-link style="color: red; margin-right: 5px; font-size: 14px">{{s.row.channelAlias}}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="todayReallyAmount" label="昨日金额">
              <template slot-scope="s">
                <el-link style="color: orange; margin-right: 5px; font-size: 14px">￥{{ parseFloat(s.row.yesterReallyAmount).toFixed(2)}}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="yesterReallyAmount" label="今日金额">
              <template slot-scope="s">
                <el-link style="color: orange; margin-right: 5px; font-size: 14px">￥{{ parseFloat(s.row.todayReallyAmount).toFixed(2)}}</el-link>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['params'],
  data() {
    return {
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p = {
        // 查询参数
        mchId: this.sa_admin.mchId
      };
      this.sa.ajax(
        "/v1/dataAnalysis/dataAnalysisPayTypeToDay",
        p,
        function (res) {
          this.dataList = res.data; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    update: function (mchId, codeMchId) {
      this.p.mchId = mchId || '';
      this.p.codeMchId = codeMchId || '';
      this.f5();
    }
  },
  created: function () {
    this.f5();
  },
};
</script>

<style></style>
